<template>
  <footer class="bg-primary" >
    <cookie-law theme="dark-lime" buttonText="Ok" class="bg-info small">
       <div slot="message">
         <p>{{$t('gender app uses cookies')}}</p>
    <p><router-link to="tos">{{$t('read more in the')}} {{$t('terms of service')}}</router-link></p>
  </div>
    </cookie-law>
  <div class="gender-app-footer">
    <div class="text-center">
      <span class="pr-2">💞 gender app {{new Date().getFullYear()}}</span>·
      <a class="m-2 fab fa-instagram" href="https://instagram.com/gender_app"></a>
      <a class="m-2 fab fa-facebook" href="https://www.facebook.com/genderapp"></a>
      <a class="m-2 fab fa-patreon" href="https://www.patreon.com/genderapp"></a>
    </div>
  </div>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'GenderAppFooter',
  components: { CookieLaw }
}

</script>

<style scoped>
  .gender-app-footer {
    /*background-color: #1b3550;*/
    /*color: white;*/
    position: absolute;
    bottom: 1px;
    width: 100%;
  }
</style>
